/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { number, object } from 'yup';
import momentTimezone from 'moment-timezone';
import { ReactComponent as JoinIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/pngegg 1.svg';
import { ReactComponent as GroupByIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/image 1.svg';
import { ReactComponent as UnionIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/pngegg 2.svg';
import { ReactComponent as CodeIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/code.svg';
import { ReactComponent as SplitIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/split-solid-svgrepo-com 1.svg';
import { ReactComponent as AddColumnIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/add-column-svgrepo-com 1.svg';
import { ReactComponent as DeleteColumnIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/add-column-svgrepo-com 1 (1).svg';
import { ReactComponent as FilterIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/Group 1320.svg';
import { ReactComponent as DatasetIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/Group 1321.svg';
import { ReactComponent as SqlIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/sql-database-generic-svgrepo-com 1.svg';
import { ReactComponent as OutputIcon } from '../assets/images/AddSourceIcons/ETLPlaygroundIcon/output-solid-svgrepo-com 1.svg';

import FileUploadImage from '../assets/images/AddSourceIcons/SelectSchemaIcon/FileUploadImage.png';
import ApiImage from '../assets/images/AddSourceIcons/SelectSchemaIcon/APIImage.png';
import StreamImage from '../assets/images/AddSourceIcons/SelectSchemaIcon/Stream.png';
import UploadFolder from '../assets/images/AddSourceIcons/SelectSchemaIcon/uploadFolder.png';

import { ReactComponent as DataSourceIcon } from '../assets/icons/DataDashboardIcons/ReportIcon/DataSourceIcon.svg';
import { ReactComponent as DataTypeIcon } from '../assets/icons/DataDashboardIcons/ReportIcon/DataTypeIcon.svg';
import { ReactComponent as StartTimeIcon } from '../assets/icons/DataDashboardIcons/ReportIcon/StartTimeIcon.svg';
import { ReactComponent as EndTimeIcon } from '../assets/icons/DataDashboardIcons/ReportIcon/EndTimeIcon.svg';
import { ReactComponent as DurationIcon } from '../assets/icons/DataDashboardIcons/ReportIcon/DurationIcon.svg';

// @ts-ignore
const VALIDATION_MESSAGES = {
  INVALID_REGEX_MESSAGE: 'Invalid Input.',
  USER_SCHEMA_NAME_INVALID_REGEX_MESSAGE:
    'The schema name should not contain any spaces, it cannot start with a special character, and it must include at least one alphabet.',
  ALPHA_NUMERIC_UNDERSCORE_INVALID_REGEX_MESSAGE:
    'Invalid entry. Allowed characters: A-Z, a-z, 0-9, and _. Please revise your input.',
};

// @ts-ignore
const OPERATIONS = [
  {
    id: 1,
    name: 'Join',
    value: 'Join',
    // @ts-ignore
    icon: <JoinIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 2,
    name: 'Group By',
    value: 'GroupBy',
    // @ts-ignore
    icon: <GroupByIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 3,
    name: 'Union',
    value: 'Union',
    // @ts-ignore
    icon: <UnionIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 4,
    name: 'Code',
    value: 'Code',
    // @ts-ignore
    icon: <CodeIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 5,
    name: 'Split',
    value: 'Split',
    // @ts-ignore
    icon: <SplitIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 6,
    name: 'Add Column',
    value: 'AddColumn',
    // @ts-ignore
    icon: <AddColumnIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 7,
    name: 'Delete Column',
    value: 'DeleteColumn',
    // @ts-ignore
    icon: <DeleteColumnIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 8,
    name: 'Filter',
    value: 'Filter',
    // @ts-ignore
    icon: <FilterIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 9,
    name: 'DataSet',
    value: 'Dataset',
    // @ts-ignore
    icon: <DatasetIcon style={{ width: '24px', height: '24px' }} />,
    disabled: true,
  },
  {
    id: 10,
    name: 'SQL',
    value: 'SQL',
    // @ts-ignore
    icon: <SqlIcon style={{ width: '24px', height: '24px' }} />,
  },
  {
    id: 11,
    name: 'Output',
    value: 'Output',
    disabled: true,
    // @ts-ignore
    icon: <OutputIcon style={{ width: '24px', height: '24px' }} />,
  },
];

const cardData = [
  {
    id: 'File',
    heading: 'File Upload',
    imgSource: FileUploadImage,
  },
  {
    id: 'Folder',
    heading: 'Folder Upload',
    imgSource: UploadFolder,
  },
  {
    id: 'api',
    heading: 'API',
    imgSource: ApiImage,
  },
  {
    id: 'stream',
    heading: 'Stream',
    imgSource: StreamImage,
  },
  {
    id: 'connector',
    heading: 'Connector',
    imgSource: ApiImage,
  },
];

const ENGINE_TYPE = [
  {
    key: 'R',
    value: 'r',
  },
  {
    key: 'SQL',
    value: 'sql',
  },
  {
    key: 'PySpark',
    value: 'pyspark',
  },
  {
    key: 'Default Python',
    value: 'default_python',
  },
];

const DATA_TYPES = [
  { label: 'TEXT', value: 'Character' },
  { label: 'INTEGER', value: 'Integer' },
  { label: 'FLOAT', value: 'Decimal' },
  { label: 'DATAFRAME', value: 'dataframe' },
];

const INPUT_TYPES = [
  { label: 'SINGLE SELECT', value: 'SINGLE_SELECT' },
  { label: 'TEXTFIELD', value: 'TEXTFIELD' },
];

const INTERVAL_OPTIONS = [
  {
    value: 'by_minute',
    label: 'By Minute',
  },
  {
    value: 'hourly',
    label: 'Hourly',
  },
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'yearly',
    label: 'Yearly',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];

const WEEKDAYS_OPTIONS = [
  { label: 'Sunday', value: '0' },
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
];

const MONTHS_OPTIONS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

const ingestionOverviewSection = [
  {
    name: 'Data Source',
    icon: <DataSourceIcon />,
  },
  {
    name: 'Data Type',
    icon: <DataTypeIcon />,
  },
  {
    name: 'Ingestion Start Time',
    icon: <StartTimeIcon />,
  },
  {
    name: 'Ingestion Completion Time',
    icon: <EndTimeIcon />,
  },
  {
    name: 'Total Ingestion Duration',
    icon: <DurationIcon />,
  },
];

const CUSTOM_CRON_EXPRESSION_HELPER_TEXT =
  'Cron expressions can be as simple as * * * * ? * or as complex as 0 0/5 14,18,3-39,52 ? JAN,MAR,SEP MON-FRI 2002-2010.';

// dummy fields
const FIELDS = [
  { name: 'name', label: 'Name', datatype: 'text' },
  {
    name: 'birthday',
    label: 'Birthday',
    datatype: 'date',
    inputType: 'date',
  },
  {
    name: 'age',
    label: 'Age',
    datatype: 'number',
    inputType: 'number',
  },
  {
    name: 'favoriteMovie',
    label: 'Favorite Movie',
    datatype: 'text',
    operators: [{ name: '=', label: 'is' }],
  },
];

const TEXT_OPERATORS = [
  'contains',
  'beginsWith',
  'endsWith',
  'doesNotContain',
  'doesNotBeginWith',
  'doesNotEndWith',
  'null',
  'notNull',
  'in',
  'notIn',
];

const NUMBER_OPERATORS = [
  { name: '<', label: 'less than' },
  { name: '<=', label: 'less than or equal to' },
  { name: '>', label: 'greater than' },
  { name: '>=', label: 'greater than or equal to' },
];

const DATE_OPERATORS = [
  { name: '=', label: 'on' },
  { name: '!=', label: 'not on' },
  { name: '<', label: 'before' },
  { name: '<=', label: 'on or before' },
  { name: '>', label: 'after' },
  { name: '>=', label: 'on or after' },
];

const EQUALITY_OPERATORS = [
  { name: '=', label: 'is' },
  { name: '!=', label: 'is not' },
];
const NULL_OPERATORS = ['null', 'notNull'];

const JOIN_TYPES = [
  { value: 'inner', label: 'Inner' },
  { value: 'left', label: 'Left' },
  { value: 'right', label: 'Right' },
  { value: 'full', label: 'Full' },
  { value: 'cross', label: 'Cross' },
  { value: 'self', label: 'Self' },
  { value: 'natural', label: 'Natural' },
  { value: 'outer', label: 'Outer' },
];

const GENERALISED_DATATYPES = {
  STRING: 'string',
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  DATE: 'date',
};

const SAME_OUTPIT_AS_INPUT_FLAG = '*';

const AGGREGATION_OPTIONS = [
  {
    value: 'count',
    label: 'COUNT',
    outputDataType: 'integer',
    allowedDataTypes: [
      GENERALISED_DATATYPES.STRING,
      GENERALISED_DATATYPES.NUMBER,
      GENERALISED_DATATYPES.DATE,
      GENERALISED_DATATYPES.BOOLEAN,
    ],
  },
  {
    value: 'sum',
    label: 'SUM',
    outputDataType: 'integer',
    allowedDataTypes: [GENERALISED_DATATYPES.NUMBER],
  },
  {
    value: 'avg',
    label: 'AVG',
    outputDataType: 'float',
    allowedDataTypes: [GENERALISED_DATATYPES.NUMBER],
  },
  {
    value: 'min',
    label: 'MIN',
    outputDataType: SAME_OUTPIT_AS_INPUT_FLAG,
    allowedDataTypes: [
      GENERALISED_DATATYPES.NUMBER,
      GENERALISED_DATATYPES.DATE,
    ],
  },
  {
    value: 'max',
    label: 'MAX',
    outputDataType: SAME_OUTPIT_AS_INPUT_FLAG,
    allowedDataTypes: [
      GENERALISED_DATATYPES.NUMBER,
      GENERALISED_DATATYPES.DATE,
    ],
  },
  {
    value: 'stddev',
    label: 'STDDEV',
    outputDataType: 'float',
    allowedDataTypes: [GENERALISED_DATATYPES.NUMBER],
  },
  {
    value: 'variance',
    label: 'VARIANCE',
    outputDataType: 'float',
    allowedDataTypes: [GENERALISED_DATATYPES.NUMBER],
  },
  {
    value: 'median',
    label: 'MEDIAN',
    outputDataType: 'float',
    allowedDataTypes: [
      GENERALISED_DATATYPES.NUMBER,
      GENERALISED_DATATYPES.STRING,
    ],
  },
  {
    value: 'mode',
    label: 'MODE',
    outputDataType: SAME_OUTPIT_AS_INPUT_FLAG,
    allowedDataTypes: [GENERALISED_DATATYPES.NUMBER],
  },
  {
    value: 'first',
    label: 'FIRST',
    outputDataType: SAME_OUTPIT_AS_INPUT_FLAG,
    allowedDataTypes: [
      GENERALISED_DATATYPES.STRING,
      GENERALISED_DATATYPES.NUMBER,
      GENERALISED_DATATYPES.DATE,
      GENERALISED_DATATYPES.BOOLEAN,
    ],
  },
  {
    value: 'last',
    label: 'LAST',
    outputDataType: SAME_OUTPIT_AS_INPUT_FLAG,
    allowedDataTypes: [
      GENERALISED_DATATYPES.STRING,
      GENERALISED_DATATYPES.NUMBER,
      GENERALISED_DATATYPES.DATE,
      GENERALISED_DATATYPES.BOOLEAN,
    ],
  },
];

const SCENARIO_STATUSES = {
  RUNNING: 'running',
  SUCCESS: 'success',
  FAILED: 'failed',
  DRAFT: 'draft',
  NOT_INITIATED: 'not initiated',
};

const THRESHOLD_FILE_UPLOAD_TYPES = {
  FLAT_FILES: 'flat_files',
  FOLDER: 'folder',
};

const FILTER_DATE_FORMAT = 'YYYY/MM/DD';

const YEARS_MONTHS_DAYS_INPUT_VALIDATION = object({
  years: number().min(0, 'Years cannot be negative').required('Required'),
  months: number()
    .min(0, 'Months cannot be negative')
    .max(11, 'Months cannot exceed 11')
    .required('Required'),
  days: number().when(['years', 'months'], {
    is: (years, months) => years > 0 || months > 0,
    then: number()
      .min(0, 'Days cannot be negative')
      .max(30, 'Days cannot exceed 30')
      .required('Required'),
    otherwise: number()
      .min(1, 'At least 1 day is required')
      .max(30, 'Days cannot exceed 30')
      .required('Required'),
  }),
}).required('Required');

const PAGE_TYPES = {
  CURRENT_PAGE: 'current_page',
  INITIAL_PAGE: 'initial_page',
  ALL_PAGES: 'all_pages',
};

const MAX_PAGINATION_PAGE_SIZE = 10;

const CODE_EDITOR_LANGUAGES_OPTIONS = [
  { label: 'Python', value: 'python' },
  { label: 'SQL', value: 'sql' },
  { label: 'JavaScript', value: 'javascript' },
];

const MAX_CPU_LIMIT_OPTIONS = [
  { label: '1 Core', value: 1 },
  { label: '2 Core', value: 2 },
  { label: '3 Core', value: 3 },
  { label: '4 Core', value: 4 },
];

const COMPUTE_TIMEOUT_OPTIONS = [
  { label: '1 Minute', value: 60 },
  { label: '3 Minutes', value: 180 },
  { label: '5 Minutes', value: 300 },
  { label: '15 Minutes', value: 900 },
  { label: '20 Minutes', value: 1200 },
];

const MAX_MEMORY_LIMIT_OPTIONS = [
  { label: '1000 MB', value: 1000 },
  { label: '2000 MB', value: 2000 },
  { label: '3000 MB', value: 3000 },
  { label: '4000 MB', value: 4000 },
  { label: '5000 MB', value: 5000 },
];

const TIMEZONE_OPTIONS = momentTimezone.tz
  .names()
  .map((tz) => ({ label: tz, value: tz }));

export {
  ENGINE_TYPE,
  DATA_TYPES,
  INPUT_TYPES,
  OPERATIONS,
  MONTHS_OPTIONS,
  WEEKDAYS_OPTIONS,
  INTERVAL_OPTIONS,
  cardData,
  ingestionOverviewSection,
  VALIDATION_MESSAGES,
  CUSTOM_CRON_EXPRESSION_HELPER_TEXT,
  TEXT_OPERATORS,
  NUMBER_OPERATORS,
  DATE_OPERATORS,
  EQUALITY_OPERATORS,
  NULL_OPERATORS,
  FIELDS,
  JOIN_TYPES,
  AGGREGATION_OPTIONS,
  SAME_OUTPIT_AS_INPUT_FLAG,
  GENERALISED_DATATYPES,
  SCENARIO_STATUSES,
  THRESHOLD_FILE_UPLOAD_TYPES,
  FILTER_DATE_FORMAT,
  YEARS_MONTHS_DAYS_INPUT_VALIDATION,
  PAGE_TYPES,
  MAX_PAGINATION_PAGE_SIZE,
  TIMEZONE_OPTIONS,
  CODE_EDITOR_LANGUAGES_OPTIONS,
  MAX_CPU_LIMIT_OPTIONS,
  COMPUTE_TIMEOUT_OPTIONS,
  MAX_MEMORY_LIMIT_OPTIONS,
};
