// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Slide, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { init as initApm } from '@elastic/apm-rum';
import store from './state/index';
import App from './App';
import packageJson from '../package.json';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
const root = ReactDOM.createRoot(document.getElementById('root'));

try {
  // eslint-disable-next-line no-unused-vars
  const apm = initApm({
    serviceName: `${packageJson.name}-${window.CONFIG.ENV}`,

    serverUrl: `${window.CONFIG.ERROR_LOGGER}`,

    // Set service version (required for sourcemap feature)
    serviceVersion: packageJson.version,
    environment: window.CONFIG.ENV,
    active: window.CONFIG.ENV === 'production',
    distributedTracingOrigins: window.location.pathname,
    logLevel: 'warn',
  });
  window.apm = apm;
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Failed to start APM RUM Agent', e);
}

root.render(
  <Provider store={store}>
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      TransitionComponent={Slide}
      hideIconVariant={false}
      ref={notistackRef}
      action={(key) => (
        <IconButton
          aria-label="close"
          size="small"
          onClick={onClickDismiss(key)}
        >
          <CloseIcon style={{ fill: '#FFFFFF' }} color="secondary" />
        </IconButton>
      )}
    >
      <App />
    </SnackbarProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
