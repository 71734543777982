import Keycloak from 'keycloak-js';

const initOptions = {
  url: window.CONFIG.KEYCLOACK_URL,
  realm: window.CONFIG.KEYCLOACK_REALM,
  clientId: window.CONFIG.KEYCLOACK_CLIENT_ID,
  onLoad: 'check-sso', // check-sso | login-required
  KeycloakResponseType: 'code',

  // silentCheckSsoRedirectUri: (window.location.origin + "/silent-check-sso.html")
};

const keycloakConfig = new Keycloak(initOptions);

export default keycloakConfig;
